import React, { useEffect } from 'react';
import './About-Us.css'
import { useNavigate } from 'react-router-dom'

function About() {
    useEffect(() => {
        document.body.classList.add('about-us-body');
        return () => {
            document.body.classList.remove('about-us-body');
        };
    }, []);

    return (
        <div className="about-us-body">
            <div className="about-us-container">
                <div className="about-section">
                    <h2 className='about-us-title'>About Us</h2>
                    <hr className='about-us-title-underline'></hr>
                    <p className='about-us-paragraphs'>Caravan Procurement is an eminent facilitator in the intricate landscape of commodity procurement. We shoulder the demanding task of sourcing, analyzing, and presenting myriad quotations from a vast network of suppliers, thereby distilling complexity into convenience for our clients.
                        We aim to make procurement a less daunting and more accessible endeavor for businesses. We are animated by a clear vision: crafting an environment wherein procurement is a streamlined, efficient facet of operations, liberating businesses to concentrate on their principal functions.</p>
                </div>

                <div className="values-section">
                    <h2 className='about-us-title'>Values</h2>
                    <hr className='about-us-title-underline'></hr>
                    <p className='about-us-paragraphs'>At Caravan Procurement, confidentiality is more than just a policy—it's a commitment deeply woven into our ethos. We place the utmost value on safeguarding our clients' information, demonstrating this through the stringent data protection protocols we have in place. We see every interaction as an opportunity to uphold this commitment, fostering a climate of trust and discretion. This unwavering respect for privacy allows our clients to interact with confidence, secure in the knowledge that their information is in reliable hands. Our approach to confidentiality transcends the transactional, becoming a cornerstone of the trust and confidence we strive to cultivate in every business engagement.
                    </p>
                </div>

                <div className="provides-section">
                    <h2 className='about-us-title'>What We Provide</h2>
                    <hr className='about-us-title-underline'></hr>
                    <p className='about-us-paragraphs'>Caravan Procurement provides a meticulous, streamlined service designed to simplify the buying process. Our collaboration begins with the client issuing a Letter of Intent (LOI), detailing their product requirements. To maintain confidentiality and prevent unnecessary market circulation, we create a corresponding LOI using our company's name. Armed with this information, we then engage our extensive network of suppliers across various industries to gather quotations. In certain cases, we even place bids on behalf of the client.
                    </p>
                    <p className='about-us-paragraphs'>Once all necessary quotations or bids are collected and placed, our team analyzes the information, selecting the three offers that most accurately meet the client's needs. We present these options to the buyer, allowing them to make an informed decision based on their specific requirements and preferences.
                    </p>
                    <p className='about-us-paragraphs'>
                        Importantly, our services come at no cost to the buyer. Caravan Procurement operates on a commission-based model, collecting between 0.05 to 2% from the supplier. This system ensures that our interests are aligned with our clients'—we succeed only when they do. The goal is to facilitate a seamless, cost-effective, and trustworthy buying experience for all our clients, regardless of their industry or scale.

                    </p>
                </div>
            </div>

        </div>

    )
}

export default About