import React from 'react';
import './topNavigationBar.css'
import caravan_logo from '../../../assets/caravan_logo.png'
import caravan_logo_light from '../../../assets/caravan_logo_light.png'
import drawer_icon from '../../../assets/drawer_icon.png'
import drawer_icon_light from '../../../assets/drawer_icon_light.png'
import exit_icon from '../../../assets/exit_icon.png'
import { useLocation, useNavigate } from 'react-router-dom'


function TopNavigationBar({ onOverlayOpen, isNormalNavigationBar, isMenuClose }) {

  const location = useLocation();
  const isAboutPage = location.pathname === '/about';
  const isProductsPage = location.pathname === '/products';
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/home");
  };
  
  return (
    <div className='navigation-bar-main-div'>
      

      {isNormalNavigationBar ? <img onClick={navigateToHome} className='navigation-bar-main-div-logo' src={isAboutPage || isProductsPage ? caravan_logo_light : caravan_logo}
        alt={isAboutPage || isProductsPage ? 'caravan-logo-about' : 'caravan-logo'}></img>
        : <img className='navigation-bar-main-div-logo' src={caravan_logo_light} alt='caravan-logo-light'></img>}

      <div className='navigation-bar-right-div'>
        {isNormalNavigationBar ? <span onClick={navigateToHome} className={`${location.pathname === "/home" || location.pathname === "/"
          ? "navigation-bar-right-div-span-active"
          : "navigation-bar-right-div-span-inactive"
          }`}>HOME</span> : null}

        {isNormalNavigationBar ?
          <img onClick={onOverlayOpen} className={isAboutPage || isProductsPage ? 'navigation-bar-right-div-drawer-light':'navigation-bar-right-div-drawer'} src={isAboutPage || isProductsPage ? drawer_icon_light:drawer_icon} alt='drawer-logo'></img>
          :
          <img onClick={isMenuClose} className='navigation-bar-right-div-exit' src={exit_icon} alt='exit-logo'></img>
        }
      </div>
    </div>
  )
}

export default TopNavigationBar