import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import './overlayNavigatopnPage';
import './overlayNavigatopnPage.css';
import TopNavigationBar from '../topNavigationBar/topNavigationBar';
import BottomCopyrightSection from '../bottomCopyrightSection/bottomCopyrightSection';
import { useLocation } from 'react-router-dom';

function OverlayNavigatopnPage({ isMenuClose, openMenuStatus }) {
    const location = useLocation();

    const item = {
        exit: {
            opacity: 0,
            height: 0,
            transition: {
                ease: "easeInOut",
                duration: 0.5,
                delay: 0.9
            }
        }
    }
    return (
        <AnimatePresence>
            {
                openMenuStatus && (
                    <motion.div className="menu_container"
                        variants={item}
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "100vh", opacity: 1 }}
                        transition={{ duration: .5 }}
                        exit="exit"
                    >
                        <div className='nav-bar-wrapper'>
                            <TopNavigationBar isNormalNavigationBar={false} isMenuClose={isMenuClose} />
                        </div>
                        <div className='list-items-wrapper'>
                            <motion.a
                                href="/home"
                                initial={{ y: 80, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: .6 }}
                                exit={{
                                    opacity: 0,
                                    y: 10,
                                    transition: {
                                        ease: "easeOut",
                                        delay: 0.8
                                    }
                                }}
                            >
                                <span className={`${location.pathname === "/home" || location.pathname === "/"
                                    ? "active"
                                    : "inactive"
                                    }`}>Home</span></motion.a>
                            <motion.a
                                href="/about"
                                initial={{ y: 80, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: .5 }}
                                exit={{
                                    opacity: 0,
                                    y: 10,
                                    transition: {
                                        ease: "easeOut",
                                        delay: .7
                                    }
                                }}
                            >
                                <span className={`${location.pathname === "/about"
                                    ? "active"
                                    : "inactive"
                                    }`}>About Us</span></motion.a>
                            <motion.a
                                href="/products"
                                initial={{ y: 80, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: .4 }}
                                exit={{
                                    opacity: 0,
                                    y: 10,
                                    transition: {
                                        ease: "easeOut",
                                        delay: .6
                                    }
                                }}
                            >
                                <span className={`${location.pathname === "/products"
                                    ? "active"
                                    : "inactive"
                                    }`}>Our Products</span>

                            </motion.a>

                            <motion.a
                                href="/legal"
                                initial={{ y: 80, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: .3 }}
                                exit={{
                                    opacity: 0,
                                    y: 10,
                                    transition: {
                                        ease: "easeOut",
                                        delay: .4
                                    }
                                }}
                            >
                                <span className={`${location.pathname === "/legal"
                                    ? "active"
                                    : "inactive"
                                    }`}>Legal</span>
                            </motion.a>
                        </div>
                        <div className='bottom-copy-right-section-wrapper'>
                            <BottomCopyrightSection isNormalBottomCopyrightSecton={false} />
                        </div>
                    </motion.div>
                )
            }
        </AnimatePresence>
    );
}

export default OverlayNavigatopnPage