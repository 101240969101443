import { React,useEffect } from 'react';
import '../../../styles/products-info.css'

function TextilesInfo() {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='products-info-container'>
            <h1 className="products-info-title">Textiles Products List:</h1>

            <h3 class="products-info-subtitles">Apparel Fabrics:</h3>
            <ul class="products-info-list">
                <li>Cotton (varieties include Pima, Egyptian, organic)</li>
                <li>Silk (Chiffon, Taffeta, Dupioni)</li>
                <li>Wool (Merino, Cashmere, Angora)</li>
                <li>Linen</li>
                <li>Polyester</li>
                <li>Nylon</li>
                <li>Spandex</li>
                <li>Denim</li>
                <li>Velvet</li>
                <li>Satin</li>
                <li>Tweed</li>
                <li>Flannel</li>
                <li>Knit fabrics (Jersey, Rib, Interlock)</li>
                <li>Lace</li>
                <li>Brocade</li>
            </ul>

            <h3 class="products-info-subtitles">Technical Textiles:</h3>
            <ul class="products-info-list">
                <li>Geotextiles (Woven, Nonwoven)</li>
                <li>Medical textiles (Surgical gowns, Drapes, Bandages)</li>
                <li>Automotive textiles (Upholstery, Airbag fabrics, Seat belts)</li>
                <li>Agrotextiles (Shade nets, Crop covers, Mulch mats)</li>
                <li>Protective clothing (Firefighter uniforms, Bulletproof vests)</li>
            </ul>

            <h3 class="products-info-subtitles">Home Textiles:</h3>
            <ul class="products-info-list">
                <li>Bed linens (Sheets, Pillowcases, Duvet covers)</li>
                <li>Towels (Bath, Hand, and Wash towels)</li>
                <li>Curtains and Draperies</li>
                <li>Upholstery fabric (Microfiber, Jacquard, Leather)</li>
                <li>Carpets and Rugs</li>
                <li>Table linens (Tablecloths, Napkins, Runners)</li>
                <li>Mattress fabrics</li>
                <li>Blankets and Throws</li>
            </ul>

            <h3 class="products-info-subtitles">Industrial Textiles:</h3>
            <ul class="products-info-list">
                <li>Conveyor belt fabrics</li>
                <li>Filtration fabrics (for air and liquid filters)</li>
                <li>Cleaning cloths (Microfiber, Oil absorbent)</li>
                <li>Flame-resistant materials (Aramid fibers, Modacrylic)</li>
                <li>Canvas (for tarps and covers)</li>
            </ul>

            <h3 class="products-info-subtitles">Performance Textiles:</h3>
            <ul class="products-info-list">
                <li>Waterproof fabrics (Gore-Tex, Coated nylons)</li>
                <li>Sports performance fabrics (Moisture-wicking, Compression)</li>
                <li>Insulated textiles (Thinsulate, Primaloft)</li>
                <li>Breathable fabrics (for active wear and footwear)</li>
            </ul>

            <h3 class="products-info-subtitles">Eco-friendly Textiles:</h3>
            <ul class="products-info-list">
                <li>Organic cotton</li>
                <li>Bamboo fabric</li>
                <li>Hemp fabric</li>
                <li>Recycled polyester</li>
                <li>Lyocell and Modal (brands like Tencel)</li>
                <li>Biodegradable fabrics</li>
            </ul>

            <h3 class="products-info-subtitles">Leather and Faux Leather:</h3>
            <ul class="products-info-list">
                <li>Cowhide</li>
                <li>Suede</li>
                <li>Nubuck</li>
                <li>Patent leather</li>
                <li>PU leather (Polyurethane leather)</li>
                <li>PVC leather (Polyvinyl chloride leather)</li>
            </ul>

            <h3 class="products-info-subtitles">Textile Composites:</h3>
            <ul class="products-info-list">
                <li>Carbon fiber composites</li>
                <li>Fiberglass fabrics</li>
                <li>Kevlar composites (for ballistic protection)</li>
                <li>Hybrid fabrics (mixed fiber content for enhanced properties)</li>
            </ul>

            <h3 class="products-info-subtitles">Non-woven Fabrics:</h3>
            <ul class="products-info-list">
                <li>Spunbond nonwovens</li>
                <li>Meltblown nonwovens</li>
                <li>Needle-punched nonwovens</li>
                <li>Thermal-bonded nonwovens</li>
                <li>Hygiene products (diapers, feminine hygiene)</li>
                <li>Medical nonwovens (face masks, gowns)</li>
            </ul>

            <h3 class="products-info-subtitles">Specialty Fabrics:</h3>
            <ul class="products-info-list">
                <li>Fire retardant fabrics (treated cotton, Nomex)</li>
                <li>Antimicrobial fabrics (for healthcare and sportswear)</li>
                <li>UV protective fabrics (for outdoor use and clothing)</li>
                <li>Stain-resistant fabrics (Teflon coated, Nanotex)</li>
            </ul>







        </div>
    );
}

export default TextilesInfo;
