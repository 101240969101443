import React from 'react'
import './home.css'
import desertImage from '../../assets/desert_main_photo.png'
import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate();
  const navigateToLegalPage = () => {
    navigate("/about")
  }
  return (
    <div className="home-main-div">
      <img className="home-main-div-img" src={desertImage} alt='desert'></img>
      <div className="home-sub-div">
        <span>Procurement Perfected</span>
        <button className="read-more-btn" onClick={navigateToLegalPage}>READ MORE</button>
        </div>
    </div>
  )
}

export default Home