import React, { useState } from "react";
import "./legal.css";
import { useNavigate } from 'react-router-dom'

function Legal() {
  const [legalText, setLegalText] = useState(
    "<p>Welcome to Caravan Procurement's website. These Terms of Use govern your access to and use of our website and services. By accessing this website, you are agreeing to comply with and be bound by the following terms, which, along with our Privacy Policy, constitute our agreement with you.</p>\
       <h4>1. Use of the Site</h4> <p>This website is provided for your personal and non-commercial use, subject to the terms and conditions set forth herein.</p>\
       <h4>2. Copyright and Trademarks</h4> <p>The content of these pages, including but not limited to text, graphics, logos, and software, is the property of Caravan Procurement and is protected by copyright and trademark laws.</p>\
       <h4>3. Disclaimers</h4> <p>Information on this website is provided \"as is\" without warranty of any kind, and we do not guarantee the accuracy, completeness, or timeliness of this information.</p>"
  );
  const [termsOfUseButton, setTermsOfUseButton] = useState(
    "legal-mini-bar-buttons-selected"
  );
  const [privacyPolicyButton, setPrivacyPolicyButton] = useState(
    "legal-mini-bar-buttons"
  );
  const [disclaimerButton, setDesclaimerButton] = useState(
    "legal-mini-bar-buttons"
  );
  const navigate = useNavigate();


  const getTermsOfUse = () => {
    setLegalText(
      "<p>Welcome to Caravan Procurement's website. These Terms of Use govern your access to and use of our website and services. By accessing this website, you are agreeing to comply with and be bound by the following terms, which, along with our Privacy Policy, constitute our agreement with you.</p>\
       <h4>1. Use of the Site</h4> <p>This website is provided for your personal and non-commercial use, subject to the terms and conditions set forth herein.</p>\
       <h4>2. Copyright and Trademarks</h4> <p>The content of these pages, including but not limited to text, graphics, logos, and software, is the property of Caravan Procurement and is protected by copyright and trademark laws.</p>\
       <h4>3. Disclaimers</h4> <p>Information on this website is provided \"as is\" without warranty of any kind, and we do not guarantee the accuracy, completeness, or timeliness of this information.</p>"
    );
    setTermsOfUseButton("legal-mini-bar-buttons-selected");
    setPrivacyPolicyButton("legal-mini-bar-buttons");
    setDesclaimerButton("legal-mini-bar-buttons");
  };

  const getPrivacyPolicy = () => {
    setLegalText(
      "<p>Your privacy is of the utmost importance to us. This policy provides information about how Caravan Procurement collects, uses, and protects your personal data.</p>\
       <h4>1. Data Collection and Use</h4> <p>We collect personal data that you provide to us when using our services or signing up for our newsletter.</p>\
       <h4>2. Data Protection</h4> <p>We implement security measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction.</p>"
    );
    setPrivacyPolicyButton("legal-mini-bar-buttons-selected");
    setTermsOfUseButton("legal-mini-bar-buttons");
    setDesclaimerButton("legal-mini-bar-buttons");
  };

  const getDisclaimer = () => {
    setLegalText(
      "<p>The information on Caravan Procurement's website is for general informational purposes only. We endeavor to keep the information up to date and correct but make no warranties of any kind about the completeness, accuracy, or availability of the website or its contents.</p>"
    );
    setDesclaimerButton("legal-mini-bar-buttons-selected");
    setTermsOfUseButton("legal-mini-bar-buttons");
    setPrivacyPolicyButton("legal-mini-bar-buttons");
  };

  const navigateToHome = () => {
    navigate("/home")
  };

  return (
    <div className="legal-main-div">
      <span className="legal-title-text">Legal</span>
      <div className="legal-mini-bar">
        <button className={termsOfUseButton} onClick={getTermsOfUse}>
          Terms of Use
        </button>
        <button className={privacyPolicyButton} onClick={getPrivacyPolicy}>
          Privacy Policy
        </button>
        <button className={disclaimerButton} onClick={getDisclaimer}>
          Disclaimer
        </button>
      </div>
      <div className="legal-main-text-container">
        <span
          className="legal-main-text"
          dangerouslySetInnerHTML={{ __html: legalText }}
        />
      </div>
      <div className="legal-home-navigator-div">
        <button className="legal-home-navigator-button" onClick={navigateToHome}>
          Home
        </button>
      </div>
    </div>
  );
}

export default Legal;
