import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import chemicals from '../../assets/industrial_chemicals_photo.jpeg'
import metals from '../../assets/construction_materials_photo.jpeg'
import agriculture from '../../assets/agriculture_inputs_photo.jpeg'
import energy from '../../assets/energy_photo.jpeg'
import textiles from '../../assets/textiles_photo.jpeg'

import './products.css'
import { useNavigate } from 'react-router-dom'

function Products() {
    const navigate = useNavigate();

    const energyButtonClick = () => {

        navigate('/products/energy-and-fuel');
    };
    const chemicalsButtonClick = () => {

        navigate('/products/industrial-chemicals');
    };
    const agriculutreButtonClick = () => {

        navigate('/products/agriculture-inputs');
    };
    const materialsButtonClick = () => {

        navigate('/products/construction-materials');
    };
    const textilesButtonClick = () => {

        navigate('/products/textiles');
    };

    useEffect(() => {
        document.body.classList.add('products-body');
        return () => {
            document.body.classList.remove('products-body');
        };
    }, []);

    return (
        <div>
            <div className='products-container'>
                <div className='products-subcontainer'>
                    <h1 className='products-title'>Energy and Fuel:</h1>
                    <hr className='products-title-breaker'></hr>
                    <h3 className='products-subtitle'>Energizing Your Operations with Comprehensive Fuel Solutions</h3>
                    <p className='products-info'>At Caravan Procurement, we understand the critical role that energy and fuel play in driving industries and economies. We provide streamlined access to a diversified portfolio of energy products, fuel options, and power solutions that cater to the evolving needs of our clients. Explore our carefully selected array of products designed to keep your operations running efficiently and sustainably.</p>
                    <button className='products-read-more-btn' onClick={energyButtonClick}>
                        Read More
                    </button>


                </div>
                <img className='products-img' src={energy} ></img>
            </div>

            <div className='products-container'>
                <div className='products-subcontainer'>
                    <h1 className='products-title'>Agricultural Inputs:</h1>
                    <hr className='products-title-breaker'></hr>
                    <h3 className='products-subtitle'>Connecting You to Premium Agricultural Inputs</h3>
                    <p className='products-info'>
                        In the dynamic field of agriculture, Caravan Procurement stands as your trusted partner, facilitating access to an array of high-quality agricultural inputs essential for modern farming operations. We navigate the complexities of sourcing to provide you with products that enhance productivity and sustainability. Below is a comprehensive outline of the agricultural inputs we can procure, reflecting our commitment to empowering your agricultural pursuits with the finest products available.

                    </p>
                    <button className='products-read-more-btn' onClick={agriculutreButtonClick}>Read More</button>


                </div>
                <img className='products-img' src={agriculture} ></img>
            </div>

            <div className='products-container'>
                <div className='products-subcontainer'>
                    <h1 className='products-title'>Industrial Chemicals:</h1>
                    <hr className='products-title-breaker'></hr>
                    <h3 className='products-subtitle'>Expertise in Industrial Chemical Procurement</h3>
                    <p className='products-info'>Caravan Procurement excels in the sourcing of industrial chemicals, serving a multitude of sectors with precision and compliance. Our rigorous selection process ensures a supply of high-caliber industrial chemicals that adhere to both industry standards and environmental safety regulations. The list below exemplifies the breadth of our procurement capabilities, offering a glimpse into the diverse chemical products that we can seamlessly integrate into your supply chain.</p>
                    <button className='products-read-more-btn' onClick={chemicalsButtonClick}>Read More</button>
                </div>
                <img className='products-img' src={chemicals} ></img>
            </div>

            <div className='products-container'>
                <div className='products-subcontainer'>
                    <h1 className='products-title'>Construction Materials:</h1>
                    <hr className='products-title-breaker'></hr>
                    <h3 className='products-subtitle'>Explore our products offering</h3>
                    <p className='products-info'>Our extensive network spans the globe, ensuring access to premium construction materials, from foundational substances to specialized finishes. Below is a curated list of the diverse construction materials we can help you source, reflecting our dedication to supporting your construction endeavors with reliability and excellence.</p>
                    <button className='products-read-more-btn' onClick={materialsButtonClick}>Read More</button>
                </div>
                <img className='products-img' src={metals} ></img>
            </div>

            <div className='products-container'>
                <div className='products-subcontainer'>
                    <h1 className='products-title'>Textiles:</h1>
                    <hr className='products-title-breaker'></hr>
                    <h3 className='products-subtitle'>Explore Our Textile offerings</h3>
                    <p className='products-info'>Navigate through the extensive spectrum of textile categories for which Caravan Procurement has established a robust network of supplier connections. Below, you will find a detailed display of the textile varieties we facilitate procurement for, ensuring that every fabric acquired through our services meets the pinnacle of industry standards and client expectations.</p>
                    <button className='products-read-more-btn' onClick={textilesButtonClick}>Read More</button>
                </div>
                <img className='products-img' src={textiles} ></img>
            </div>
        </div>


    )
}

export default Products
