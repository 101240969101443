// Layout.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TopNavigationBar from "../topNavigationBar/topNavigationBar";
import BottomCopyrightSection from "../bottomCopyrightSection/bottomCopyrightSection";
import OverlayNavigatopnPage from "../overlayNavigationPage/overlayNavigatopnPage";
import Footer from '../bottomFooter/footer';


function Layout({ isMenuOpen, isMenuClose, openMenuStatus, content }) {
  const location = useLocation();
  const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    const homeText = document.querySelector('.navigation-bar-right-div-span-inactive');

    if (homeText) {
      if (location.pathname === '/about' || location.pathname === '/products') {
        homeText.classList.add('about-us-special-text-color');
      }
      else {
        homeText.classList.remove('about-us-special-text-color');
      }
    }

    if (location.pathname === '/home' || location.pathname === '/') {
      setIsHome(true);
    }
    else {
      setIsHome(false);
    }
  }, [location]);
  return (
    <div>
      <TopNavigationBar onOverlayOpen={isMenuOpen} isNormalNavigationBar={true} isMenuClose={isMenuClose} />
      {content}
      <BottomCopyrightSection isNormalBottomCopyrightSecton={true} />
      {isHome ? <></> : <Footer />}
      {isMenuOpen && (
        <OverlayNavigatopnPage isMenuClose={isMenuClose} openMenuStatus={openMenuStatus} />
      )}
    </div>
  );
};

export default Layout;
