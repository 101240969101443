import React from 'react'
import './footer.css'
import caravan_logo from '../../../assets/caravan_logo_light.png'
import linkedin from '../../../assets/linkedin.png'

function Footer() {
  return (
    <div className='main-footer-div'>

      <div className='main-footer-div-title'>
        <img src={caravan_logo} alt='caravan logo'></img>
      </div>

      <div className='main-footer-div-information'>
        <p>We at Caravan Procurement welcome your interest and inquiries. Our team is ready to assist you with any questions regarding our procurement services and how we can facilitate your business needs.</p>
      </div>

      <div className='main-footer-div-main-section'>
        <div>
          <span className='sub-title'>Site Map</span>
          <span><a href='/home'>Home</a></span>
          <span><a href='/about'>About Us</a></span>
          <span><a href='/products'>Products</a></span>
          <span><a href='/legal'>Legal</a></span>
          <br />

        </div>

        <div>
          <span className='sub-title'>Contact information</span>
          <br />
          <span className='mail-title'>For general inquiries: </span>
          <a href="mailto:info@caravanproc.co.uk">info@caravanproc.co.uk</a>
          <br />
          <br />
          <span className='mail-title'>For sales: </span>
          <a href="mailto:abdallah@caravanproc.co.uk">abdallah@caravanproc.co.uk</a>
          <br />
          <br />

        </div>
        <div>

          <span className='sub-title'>Buisness Hours</span>
          <br />
          <span>Monday to Friday: 9:00 am - 6:00 pm GMT</span>
          <br />
          <span>Closed on Saturdays, Sundays, and Public Holidays.</span>
          <br />

        </div>
        <div>

          <span className='sub-title'>Follow us on</span>
          <a className='social-media-logo' href="https://www.linkedin.com/company/caravan-procurement/" target='_blank' rel="noreferrer"><img src={linkedin} alt='linkedin logo'></img></a>
          <br />

        </div>
      </div>


      <div className='developed-by-container'>

      Copyrights &copy; 2023 Caravan. Developed by Y&sup2;M

      </div>

    </div>
    
  )
}

export default Footer