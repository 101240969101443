import { React,useEffect } from 'react';

import '../../../styles/products-info.css'

function MaterialsInfo() {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='products-info-container'>
            <h1 className="products-info-title">Construction Materials List:</h1>

            <h3 class="products-info-subtitles">Basic Building Materials:</h3>
            <ul class="products-info-list">
                <li>Cement</li>
                <li>Aggregates (sand, gravel, crushed stone)</li>
                <li>Ready-mix concrete</li>
                <li>Bricks and blocks (clay, concrete)</li>
                <li>Roofing materials (tiles, shingles)</li>
                <li>Timber and plywood</li>
                <li>Steel rebar and structural steel</li>
                <li>Glass (float, tempered, laminated)</li>
                <li>Aluminum (extrusions, sheeting)</li>
                <li>Bitumen (asphalt for paving, roofing felt)</li>
            </ul>

            <h3 class="products-info-subtitles">Finishing Materials:</h3>
            <ul class="products-info-list">
                <li>Paints and coatings</li>
                <li>Flooring (ceramic tiles, hardwood, laminate, vinyl)</li>
                <li>Wall coverings (wallpaper, panels)</li>
                <li>Fixtures and fittings (door handles, cabinetry hardware)</li>
                <li>Plumbing fixtures (taps, sinks, toilets)</li>
                <li>Electrical fixtures and fittings</li>
            </ul>

            <h3 class="products-info-subtitles">Specialized Construction Materials:</h3>
            <ul class="products-info-list">
                <li>Insulation materials (fiberglass, foam board, reflective)</li>
                <li>Waterproofing membranes</li>
                <li>Sealants and adhesives</li>
                <li>Geosynthetics (geotextiles, geomembranes)</li>
                <li>Acoustic materials (panels, foam, insulation)</li>
                <li>Solar panels and sustainable building materials</li>
                <li>Fireproofing materials</li>
            </ul>

            <h3 class="products-info-subtitles">Infrastructure Materials:</h3>
            <ul class="products-info-list">
                <li>Concrete pipes and culverts</li>
                <li>Manhole covers and frames</li>
                <li>Street lighting and poles</li>
                <li>Guardrails and barriers</li>
                <li>Traffic signage and safety systems</li>
                <li>Reinforced concrete (precast panels, beams)</li>
            </ul>

            <h3 class="products-info-subtitles">Construction Chemicals:</h3>
            <ul class="products-info-list">
                <li>Concrete admixtures</li>
                <li>Grouts and anchors</li>
                <li>Epoxy coatings and sealers</li>
                <li>Waterproofing chemicals</li>
                <li>Mortar and plaster additives</li>
            </ul>

            <h3 class="products-info-subtitles">Landscaping Materials:</h3>
            <ul class="products-info-list">
                <li>Pavers and blocks</li>
                <li>Outdoor tiles</li>
                <li>Retaining walls and edging</li>
                <li>Decorative stones and gravel</li>
                <li>Soil and mulches</li>
                <li>Landscaping fabric</li>
            </ul>

            <h3 class="products-info-subtitles">Construction Tools and Equipment:</h3>
            <ul class="products-info-list">
                <li>Hand tools (hammers, saws, trowels)</li>
                <li>Power tools (drills, saws, sanders)</li>
                <li>Heavy machinery (excavators, loaders, cranes)</li>
                <li>Scaffolding and ladders</li>
                <li>Safety equipment (helmets, gloves, visibility jackets)</li>
            </ul>


        </div>
    );
}

export default MaterialsInfo;
