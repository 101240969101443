import { React,useEffect } from 'react';
import '../../../styles/products-info.css'

function AgriculturalInfo() {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='products-info-container'>
            <h1 className="products-info-title">Agricultural Inputs List:</h1>

            <h3 class="products-info-subtitles">Seeds and Planting Material:</h3>
            <ul class="products-info-list">
                <li>Hybrid seeds</li>
                <li>Genetically modified (GM) seeds</li>
                <li>Organic seeds</li>
                <li>Rootstocks and cuttings</li>
                <li>Bulbs and tubers</li>
            </ul>

            <h3 class="products-info-subtitles">Fertilizers:</h3>
            <ul class="products-info-list">
                <li>Nitrogen-based (urea, ammonium nitrate)</li>
                <li>Phosphorus-based (superphosphate, DAP)</li>
                <li>Potassium-based (potash)</li>
                <li>Compound fertilizers (NPK)</li>
                <li>Organic fertilizers (compost, manure)</li>
                <li>Specialty fertilizers (chelated minerals, micronutrients)</li>
            </ul>

            <h3 class="products-info-subtitles">Crop Protection:</h3>
            <ul class="products-info-list">
                <li>Herbicides</li>
                <li>Insecticides</li>
                <li>Fungicides</li>
                <li>Rodenticides</li>
                <li>Nematicides</li>
                <li>Biocontrol agents</li>
            </ul>

            <h3 class="products-info-subtitles">Soil Amendments:</h3>
            <ul class="products-info-list">
                <li>Lime and gypsum</li>
                <li>Soil conditioners</li>
                <li>Organic matter (peat, compost)</li>
                <li>Mulches</li>
            </ul>

            <h3 class="products-info-subtitles">Irrigation and Water Management:</h3>
            <ul class="products-info-list">
                <li>Drip irrigation systems</li>
                <li>Sprinkler systems</li>
                <li>Pumps and filters</li>
                <li>Water storage solutions</li>
            </ul>

            <h3 class="products-info-subtitles">Agricultural Equipment:</h3>
            <ul class="products-info-list">
                <li>Tractors and tillers</li>
                <li>Seeders and planters</li>
                <li>Harvesting machinery</li>
                <li>Post-harvest equipment (sorters, dryers)</li>
                <li>Farming tools (hoes, spades, pruning shears)</li>
            </ul>

            <h3 class="products-info-subtitles">Livestock Inputs:</h3>
            <ul class="products-info-list">
                <li>Animal feed</li>
                <li>Veterinary medicines</li>
                <li>Vaccines</li>
                <li>Feed additives (vitamins, minerals)</li>
                <li>Breeding supplies (insemination, incubation)</li>
            </ul>

            <h3 class="products-info-subtitles">Greenhouse Supplies:</h3>
            <ul class="products-info-list">
                <li>Greenhouse film</li>
                <li>Shade nets</li>
                <li>Thermal screens</li>
                <li>Hydroponic systems</li>
                <li>Grow lights</li>
            </ul>

            <h3 class="products-info-subtitles">Supportive Inputs:</h3>
            <ul class="products-info-list">
                <li>Pesticide adjuvants</li>
                <li>Sprayers and applicators</li>
                <li>Personal protective equipment (PPE)</li>
                <li>Testing kits (soil, water, produce quality)</li>
            </ul>

            <h3 class="products-info-subtitles">Agroforestry and Landscaping:</h3>
            <ul class="products-info-list">
                <li>Tree seedlings</li>
                <li>Ornamental plants</li>
                <li>Windbreaks and shelterbelt materials</li>
                <li>Erosion control products</li>
            </ul>

        </div>
    );
}

export default AgriculturalInfo;
