import React, { useEffect, useState } from 'react';
import './bottomCopyrightSection.css'
import { useNavigate, useLocation } from 'react-router-dom'

function BottomCopyrightSection({isNormalBottomCopyrightSecton}) {
  const [isAbout, setIsAbout] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToLegalPage = () => {
    navigate("/legal")
  }

  useEffect(() => {

      if (location.pathname === '/about' || location.pathname === '/products') {
        setIsAbout(true);
      } else {
        setIsAbout(false);
      }
  }, [location]);

  return (
    <div className='bottom-copyright-main-div'>
        <div className='bottom-copyright-sub-div'>
            <span className={isNormalBottomCopyrightSecton? isAbout? 'caravan-2023-word-about':'caravan-2023-word'
            
            :'caravan-2023-word-light' }>CARAVAN © 2023 </span>
            <span onClick={navigateToLegalPage} className={isNormalBottomCopyrightSecton? isAbout?'legal-word-about':
            'legal-word':
            'legal-word-light' }>Legal</span>
        </div>


    </div>
  )
}

export default BottomCopyrightSection