// App.js
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import Layout from "./components/common/layout/layout";
import Home from "./pages/home/home";
import Legal from "./pages/legal/legal";
import About from "./pages/about-us/About-Us";
import Products from "./pages/products/products"
import EnergyAndFuelInfo from "./components/common/products-info/energy-fuel"
import AgriculturalInfo from "./components/common/products-info/agriculture"
import MaterialsInfo from "./components/common/products-info/materials"
import ChemicalsInfo from "./components/common/products-info/chemicals"
import TextilesInfo from "./components/common/products-info/textiles"


function App() {
  const [openMenuStatus, setOpenMenuStatus] = useState(false);

  const isMenuOpen = () => {
    setOpenMenuStatus(true);
  }

  const isMenuClose = () => {
    setOpenMenuStatus(false);
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<Home />}
            />
          }
        />
        <Route
          path="/home"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<Home />}
            />
          }
        />
        <Route
          path="/legal"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<Legal />}
            />
          }
        />
        <Route
          path="/about"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<About />}
            />
          }
        />
        <Route
          path="/products"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<Products />}
            />
          }
        />
        <Route
          path="/products/energy-and-fuel"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<EnergyAndFuelInfo />}
            />
          }
        />

        <Route
          path="/products/agriculture-inputs"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<AgriculturalInfo />}
            />
          }
        />

        <Route
          path="/products/construction-materials"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<MaterialsInfo />}
            />
          }
        />

        <Route
          path="/products/industrial-chemicals"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<ChemicalsInfo />}
            />
          }
        />

        <Route
          path="/products/textiles"
          element={
            <Layout
              isMenuOpen={isMenuOpen}
              isMenuClose={isMenuClose}
              openMenuStatus={openMenuStatus}
              content={<TextilesInfo />}
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;