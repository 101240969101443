import { React,useEffect } from 'react';
import '../../../styles/products-info.css'

function ChemicalsInfo() {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='products-info-container'>
            <h1 className="products-info-title">Industrial Chemicals List:</h1>

            <h3 class="products-info-subtitles">Solvents:</h3>
            <ul class="products-info-list">
                <li>Acetone</li>
                <li>Ethanol</li>
                <li>Isopropanol (IPA)</li>
                <li>Toluene</li>
                <li>Xylene</li>
                <li>Methylene chloride</li>
            </ul>

            <h3 class="products-info-subtitles">Acids and Bases:</h3>
            <ul class="products-info-list">
                <li>Sulfuric acid</li>
                <li>Hydrochloric acid</li>
                <li>Nitric acid</li>
                <li>Acetic acid</li>
                <li>Sodium hydroxide (caustic soda)</li>
                <li>Potassium hydroxide</li>
            </ul>

            <h3 class="products-info-subtitles">Plastics and Polymers:</h3>
            <ul class="products-info-list">
                <li>Polyethylene (PE)</li>
                <li>Polypropylene (PP)</li>
                <li>Polystyrene (PS)</li>
                <li>Polyvinyl chloride (PVC)</li>
                <li>Polyethylene terephthalate (PET)</li>
                <li>Polycarbonate (PC)</li>
            </ul>

            <h3 class="products-info-subtitles">Specialty Gases:</h3>
            <ul class="products-info-list">
                <li>Ammonia</li>
                <li>Helium</li>
                <li>Nitrogen</li>
                <li>Oxygen</li>
                <li>Argon</li>
                <li>Hydrogen</li>
            </ul>

            <h3 class="products-info-subtitles">Petrochemicals:</h3>
            <ul class="products-info-list">
                <li>Ethylene</li>
                <li>Propylene</li>
                <li>Butadiene</li>
                <li>Benzene</li>
                <li>Toluene</li>
                <li>Xylenes</li>
            </ul>

            <h3 class="products-info-subtitles">Cleaning Agents:</h3>
            <ul class="products-info-list">
                <li>Bleach (sodium hypochlorite)</li>
                <li>Hydrogen peroxide</li>
                <li>Ammonium compounds</li>
                <li>Phosphates</li>
                <li>Chlorinated solvents</li>
            </ul>

            <h3 class="products-info-subtitles">Adhesives and Sealants:</h3>
            <ul class="products-info-list">
                <li>Epoxies</li>
                <li>Urethanes</li>
                <li>Silicones</li>
                <li>Acrylics</li>
                <li>Cyanoacrylates</li>
            </ul>

            <h3 class="products-info-subtitles">Agricultural Chemicals:</h3>
            <ul class="products-info-list">
                <li>Fertilizers (urea, ammonium nitrate)</li>
                <li>Pesticides (herbicides, insecticides, fungicides)</li>
                <li>Growth agents</li>
            </ul>

            <h3 class="products-info-subtitles">Textile Chemicals:</h3>
            <ul class="products-info-list">
                <li>Dyes and pigments</li>
                <li>Fixatives</li>
                <li>Bleaching agents</li>
                <li>Scouring agents</li>
                <li>Softeners</li>
            </ul>

            <h3 class="products-info-subtitles">Food Grade Chemicals:</h3>
            <ul class="products-info-list">
                <li>Citric acid</li>
                <li>Ascorbic acid</li>
                <li>Baking soda (sodium bicarbonate)</li>
                <li>Food colorants</li>
                <li>Preservatives</li>
            </ul>

            <h3 class="products-info-subtitles">Pharmaceutical Ingredients:</h3>
            <ul class="products-info-list">
                <li>Active Pharmaceutical Ingredients (APIs)</li>
                <li>Excipients</li>
                <li>Intermediates</li>
                <li>Buffers</li>
            </ul>

            <h3 class="products-info-subtitles">Water Treatment Chemicals:</h3>
            <ul class="products-info-list">
                <li>Alum (aluminum sulfate)</li>
                <li>Polymer flocculants</li>
                <li>Biocides</li>
                <li>Corrosion inhibitors</li>
                <li>Scale inhibitors</li>
            </ul>

            <h3 class="products-info-subtitles">Lubricants and Greases:</h3>
            <ul class="products-info-list">
                <li>Mineral oils</li>
                <li>Synthetic oils</li>
                <li>Greases</li>
                <li>Anti-wear additives</li>
            </ul>

            <h3 class="products-info-subtitles">Coatings and Paints:</h3>
            <ul class="products-info-list">
                <li>Resins</li>
                <li>Pigments</li>
                <li>Additives</li>
                <li>Solvents</li>
                <li>Fillers</li>
            </ul>

            <h3 class="products-info-subtitles">Refrigerants:</h3>
            <ul class="products-info-list">
                <li>Freon gases</li>
                <li>Hydrofluorocarbons (HFCs)</li>
                <li>Ammonia</li>
            </ul>

            <h3 class="products-info-subtitles">Rubber Processing Chemicals:</h3>
            <ul class="products-info-list">
                <li>Vulcanizing agents</li>
                <li>Accelerators</li>
                <li>Antioxidants</li>
                <li>Processing oils</li>
            </ul>



        </div>
    );
}

export default ChemicalsInfo;
