// EnergyAndFuelInfo.js
import { React,useEffect } from 'react';
import '../../../styles/products-info.css'

function EnergyAndFuelInfo() {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='products-info-container'>
            <h1 className="products-info-title">Energy and Fuel Product List</h1>

            <h3 className="products-info-subtitles">Fuels:</h3>
            <ul className="products-info-list">
                <li>Diesel</li>
                <li>Petrol (Gasoline)</li>
                <li>Jet fuel (Aviation fuel)</li>
                <li>Marine fuel</li>
                <li>Biofuel (Biodiesel, Ethanol)</li>
                <li>Liquefied Petroleum Gas (LPG)</li>
                <li>Compressed Natural Gas (CNG)</li>
                <li>Heavy Fuel Oil (HFO)</li>
            </ul>

            <h3 className="products-info-subtitles">Generators:</h3>
            <ul className="products-info-list">
                <li>Diesel generators</li>
                <li>Gasoline generators</li>
                <li>Natural gas generators</li>
                <li>Biofuel-compatible generators</li>
                <li>Portable generators</li>
                <li>Standby generators</li>
                <li>Industrial power generators</li>
            </ul>

            <h3 className="products-info-subtitles">Batteries:</h3>
            <ul className="products-info-list">
                <li>Lead-acid batteries</li>
                <li>Lithium-ion batteries</li>
                <li>Nickel-metal hydride batteries</li>
                <li>Deep-cycle batteries</li>
                <li>Automotive batteries</li>
                <li>Industrial batteries</li>
                <li>Solar energy storage batteries</li>
            </ul>

            <h3 className="products-info-subtitles">Solar Energy Solutions:</h3>
            <ul className="products-info-list">
                <li>Photovoltaic (PV) solar panels</li>
                <li>Solar inverters</li>
                <li>Solar charge controllers</li>
                <li>Solar mounting systems</li>
                <li>Solar tracking systems</li>
                <li>Solar thermal collectors</li>
            </ul>

            <h3 className="products-info-subtitles">Wind Power Solutions</h3>
            <ul className="products-info-list">
                <li>Horizontal-axis wind turbines</li>
                <li>Vertical-axis wind turbines</li>
                <li>Offshore wind turbines</li>
                <li>Small wind energy systems</li>
                <li>Wind turbine blades and components</li>
                <li>Wind power inverters</li>
            </ul>

            <h3 className="products-info-subtitles">Energy Storage and Power Conversion:</h3>
            <ul className="products-info-list">
                <li>Grid-scale energy storage systems</li>
                <li>Flywheel energy storage</li>
                <li>Ultracapacitors</li>
                <li>Power converters</li>
                <li>Hybrid energy systems combining solar and wind</li>
            </ul>

            <h3 className="products-info-subtitles">Energy Efficiency and Management:</h3>
            <ul className="products-info-list">
                <li>Smart meters</li>
                <li>Energy management software</li>
                <li>LED lighting solutions</li>
                <li>High efficiency motors and drives</li>
            </ul>
        </div>
    );
}

export default EnergyAndFuelInfo;
